const plans=[
    {
        name: "Starter",
        price: "Free",
        description: "Unlock essential meeting tools for small teams at no cost.",
        advantages: [
            "Up to 5 users",
            "Import emails from calendar"
        ],
        isMostPopular: false
    }, {
        name: "Business",
        price: "$5 / month",
        description: "Maximize efficiency, no limits on participants or possibilities.",
        advantages: [
            "Unlimited users",
            "Import emails from calendar",
            "Priority support",
            "Early access to new features"
        ],
        isMostPopular: true,
    },{
        name: "Business Lifetime",
        price: "$25 / one time",
        description: "Invest in the future of meetings with unlimited access, forever.",
        advantages: [
            "Unlimited users",
            "Import emails from calendar",
            "Priority support",
            "Early access to new features"
        ],
        isMostPopular: false,
    },
]
export default plans;
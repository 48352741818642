import React from 'react';
import CheckPoint from "assets/images/icons/circle_point.svg";
import styled from "styled-components";
import PurpleButton from "assets/images/icons/purple_button.svg";
import CurrentPlanBadge from "assets/images/badge.svg";

const PricingCard = (
    {
        name,
        price,
        description,
        advantages,
        isCurrent,
        onClick,
        isMostPopular = false,
        showButtonUpdate = false
    }
) => {
    const handleClick = () => {
        onClick(name);
    };


    return (
        <PricingCardContainer>
            {isMostPopular && (<StyledImage src={PurpleButton}/>)}
            <UpperText>
                {name}
                {isCurrent && <img src={CurrentPlanBadge} />}
            </UpperText>
            <MainText>{price}</MainText>
            <ThinText>{description}</ThinText>
            {showButtonUpdate &&
                <Button
                    backgroundColor="#FFCF40"
                    onClick={handleClick}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span style={{marginLeft: '8px'}}>
                            <span
                                style={{
                                    fontWeight: 600,
                                }}
                            >
                                Update to {name}
                            </span>
                        </span>
                    </div>
                </Button>
            }
            <ListOfFeatures>
                {advantages.map(advantage => (
                    <Item key={advantage}>
                        <img src={CheckPoint}/>
                        <CheckPointText>{advantage}</CheckPointText>
                    </Item>
                ))}

            </ListOfFeatures>
        </PricingCardContainer>
    );
};

const PricingCardContainer = styled.div`
    position: relative;
    width: 24rem;
    height: 506px;
    border: 1px solid #cacacc;
    border-radius: 12px;

    @media (max-width: 1200px) {
        width: 90%;
        margin-bottom: 40px;
    }
`;
const UpperText = styled.div`
    margin: 2.5rem;
    font-family: 'SharpGrotesk-Medium20';
    color: #474747;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    gap: 12px;

    @media (max-width: 1200px) {
        margin: 1.5rem;
    }
`;
const MainText = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    margin: 2.5rem;
    @media (max-width: 1200px) {
        margin: 1.5rem;
    }
`;


const ThinText = styled.div`
    color: #737373;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 2.5rem;
    @media (max-width: 1200px) {
        margin: 1.5rem;
    }
`;

const ListOfFeatures = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    gap: 15px;
    @media (max-width: 1200px) {
        margin: 1.5rem;
    }
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

const CheckPointText = styled.div`
    color: #474747;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;

const StyledImage = styled.img`
    position: absolute;
    top: -27px;
    left: 33%;
`;


const Button = styled.button`
    padding: 7px 15px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 32px;
    background-color: ${(props) => props.backgroundColor};
    width: 304px;
    margin: 2.5rem;
    margin-bottom: 22px;
    @media (max-width: 1200px) {
        margin: 1.5rem;
        width: 279px;
        height: 40px;
    }
`;


export default PricingCard;

import 'assets/fonts.css';
import Header from 'components/navigation/Header';
import React from 'react';
import Footer from 'components/navigation/Footer';
import Login from 'pages/Login';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsOfUse from 'pages/TermsOfUse';
import { UserProvider } from 'components/technical/UserContext';
import SuccessPayment from 'pages/SuccessPayment';
import Pricing from 'pages/Pricing';
import MainPage from 'pages/MainPage';

import { RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom';
import ChangePlan from "pages/ChangePlan";

const router = [
    {
        id: 'root',
        path: '/',
        Component: Layout,
        children: [
            {
                index: true,
                Component: MainPage,
            },

            {
                path: 'pricing',
                Component: Pricing,
            },
            {
                path: 'privacy_policy',
                Component: PrivacyPolicy,
            },
            {
                path: 'terms_of_use',
                Component: TermsOfUse,
            },
        ],
    },
    {
        path: '/login',
        Component: Login,
    },
    {
        path: "/change_plan",
        Component: ChangePlan,
    },
    {
        path: '/success',
        Component: SuccessPayment,
    },
];

function Layout() {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    );
}

function App() {
    return (
        <UserProvider>
            <RouterProvider router={createBrowserRouter(router)} />
        </UserProvider>
    );
}

export default App;

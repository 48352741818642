

const BACKEND_URL ="https://spry-api-extension-dot-spry-398908.appspot.com";


const handleResponse = async (response) => {
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong');
    }
    return response.json();
};

export const getRequest = async (endpoint, params = {}) => {
    const url = new URL(`${BACKEND_URL}${endpoint}`);

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    const response = await fetch(url, {
        method: 'GET',
    });

    return handleResponse(response);
};

export const postRequest = async (endpoint, body, params) => {
    const url = new URL(`${BACKEND_URL}${endpoint}`);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    const response = await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
    });

    return handleResponse(response);
};


const api = {
    getRequest,
    postRequest,
}

export default api;
